@import '../../bulma-custom/utilities/initial-variables'
@import '../../bulma-custom/utilities/mixins'
@import '../../nuuns/functions'
@import '../../nuuns/mixins'
@import '../../campsider/icomoon/style'

.hero-swiper
  position: relative
  z-index: 0

  .swiper
    min-height: 214px
    .swiper-slide
      height: auto

  &__image
    img
      height: 100%
      width: 100%
      object-fit: cover

  +desktop
    padding: 30px 25px 0 25px

    .swiper
      min-height: 233px

    &__image
      img
        border-radius: 4px

  &__pagination
    position: absolute
    z-index: 10
    bottom: 12px
    left: 50%
    transform: translateX(-50%)


  &__navigation
    display: none

    +desktop
      +flexCenter
      background: $white
      height: 40px
      width: 40px
      border-radius: 50px
      overflow: hidden
      position: absolute
      transform: translateY(-50%)
      top: 50%
      z-index: 10
      cursor: pointer
      box-shadow: 0 4px 4px 0 rgba(#000, 0.15)

      &:before
        content: $icon--angle-down
        font-family: $icomoon-font-family
        font-size: 17px
        color: $black

      &:hover
        &:before
          color: $primary

      &--next
        right: 50px
        &:before
          transform: rotate(-90deg)

      &--prev
        left: 50px
        &:before
          transform: rotate(90deg)
