.home-featured-links
  padding: 20px 15px
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 33px

  &__block-title
    width: max-content
    font-size: 16px
    font-weight: $weight-medium
    margin-bottom: 15px
    &:hover
      text-decoration: underline

  &__block-list
    font-size: 14px

  &__block-list-item-link
    &:hover
      text-decoration: underline

  +tablet
    grid-template-columns: repeat(3, 1fr)

  +desktop
    grid-template-columns: repeat(5, 1fr)

  +widescreen
    padding: 30px 25px
