.home-featured-block
  padding: 25px 0

  .swiper
    padding: 0 15px !important

  .swiper-button-disabled
    opacity: 0.5
    cursor: not-allowed

  &__header
    padding: 0 15px

    display: flex
    flex-wrap: wrap
    justify-content: space-between
    gap: 20px

    margin-bottom: 15px

  &__header-title
    line-height: 1
    font-weight: $weight-medium
    font-size: 16px
    +desktop
      font-size: 18px

  &__header-subtitle
    font-size: 14px

  &__header-actions-link
    max-height: 35.5px
    height: min-content

  &__header-actions-swipe-left,
  &__header-actions-swipe-right
    display: none
    height: 35.5px
    width: 38px
    border-radius: 4px
    border: 1px solid $grey-50
    cursor: pointer
    background: white
    i
      color: $primary

  &__header-actions-swipe-left
    i
      transform: rotate(90deg)

  &__header-actions-swipe-right
    i
      transform: rotate(270deg)

  +desktop
    padding: 30px 25px 40px 25px

    .swiper
      padding: 0 !important

    &__header
      padding: 0
      margin-bottom: 25px

    &__header-actions
      display: flex

    &__header-actions-swipe
      display: flex
      gap: 12px

    &__header-actions-swipe-left
      margin-left: 26px

    &__header-actions-swipe-left,
    &__header-actions-swipe-right
      display: grid
      place-items: center

