@media screen and (max-width: 767px) {
  .concept .swiper {
    overflow: visible;
  }
}
.concept .swiper-container {
  width: 100%;
  height: 100%;
}
.concept .swiper-slide {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .concept .swiper-slide {
    width: calc(100% - 24px);
  }
}
.concept .swiper-slide > * {
  margin-left: 12px;
  margin-right: 12px;
}

.home-featured-block {
  padding: 25px 0;
}
.home-featured-block .swiper {
  padding: 0 15px !important;
}
.home-featured-block .swiper-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.home-featured-block__header {
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}
.home-featured-block__header-title {
  line-height: 1;
  font-weight: 500;
  font-size: 16px;
}
@media screen and (min-width: 1024px) {
  .home-featured-block__header-title {
    font-size: 18px;
  }
}
.home-featured-block__header-subtitle {
  font-size: 14px;
}
.home-featured-block__header-actions-link {
  max-height: 35.5px;
  height: min-content;
}
.home-featured-block__header-actions-swipe-left, .home-featured-block__header-actions-swipe-right {
  display: none;
  height: 35.5px;
  width: 38px;
  border-radius: 4px;
  border: 1px solid #D5D5D5;
  cursor: pointer;
  background: white;
}
.home-featured-block__header-actions-swipe-left i, .home-featured-block__header-actions-swipe-right i {
  color: #BE4A09;
}
.home-featured-block__header-actions-swipe-left i {
  transform: rotate(90deg);
}
.home-featured-block__header-actions-swipe-right i {
  transform: rotate(270deg);
}
@media screen and (min-width: 1024px) {
  .home-featured-block {
    padding: 30px 25px 40px 25px;
  }
  .home-featured-block .swiper {
    padding: 0 !important;
  }
  .home-featured-block__header {
    padding: 0;
    margin-bottom: 25px;
  }
  .home-featured-block__header-actions {
    display: flex;
  }
  .home-featured-block__header-actions-swipe {
    display: flex;
    gap: 12px;
  }
  .home-featured-block__header-actions-swipe-left {
    margin-left: 26px;
  }
  .home-featured-block__header-actions-swipe-left, .home-featured-block__header-actions-swipe-right {
    display: grid;
    place-items: center;
  }
}

.home-featured-card {
  box-sizing: border-box;
  width: 125px;
  height: 125px;
  border: 1px solid #D5D5D5;
  border-radius: 4px;
  user-select: none;
}
.home-featured-card:hover .home-featured-card__title {
  text-decoration: underline;
}
.home-featured-card__link:hover {
  color: #262F2C;
}
.home-featured-card__image {
  height: calc(100% - 31px);
  width: 100%;
}
.home-featured-card__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 3px 3px 0 0;
}
.home-featured-card__content {
  padding: 0 10px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-featured-card__title {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home-featured-card__title-read-more {
  display: none;
}
@media screen and (min-width: 768px), print {
  .home-featured-card {
    width: 200px;
    height: 179px;
  }
  .home-featured-card__image {
    height: calc(100% - 39px);
  }
  .home-featured-card__content {
    padding: 0 20px;
    height: 39px;
  }
  .home-featured-card__title {
    font-size: 16px;
  }
}
.home-featured-card--medium {
  width: 182px;
  height: 209px;
}
.home-featured-card--medium .home-featured-card__image {
  height: calc(100% - 51px);
}
.home-featured-card--medium .home-featured-card__content {
  height: 51px;
  border-top: 1px solid #D5D5D5;
}
@media screen and (min-width: 768px), print {
  .home-featured-card--medium {
    width: 244px;
    height: 279px;
  }
  .home-featured-card--medium .home-featured-card__image {
    height: calc(100% - 68px);
  }
  .home-featured-card--medium .home-featured-card__content {
    height: 68px;
  }
}
.home-featured-card--large, .home-featured-card--large-with-image-padding {
  width: 229px;
  height: 153px;
}
.home-featured-card--large .home-featured-card__image, .home-featured-card--large-with-image-padding .home-featured-card__image {
  height: calc(100% - 45px);
}
.home-featured-card--large .home-featured-card__content, .home-featured-card--large-with-image-padding .home-featured-card__content {
  height: 45px;
  border-top: 1px solid #D5D5D5;
}
@media screen and (min-width: 768px), print {
  .home-featured-card--large, .home-featured-card--large-with-image-padding {
    width: 422px;
    height: 225px;
  }
  .home-featured-card--large .home-featured-card__image, .home-featured-card--large-with-image-padding .home-featured-card__image {
    height: calc(100% - 64px);
  }
  .home-featured-card--large .home-featured-card__content, .home-featured-card--large-with-image-padding .home-featured-card__content {
    height: 64px;
    display: flex;
    justify-content: space-between;
  }
  .home-featured-card--large .home-featured-card__title, .home-featured-card--large-with-image-padding .home-featured-card__title {
    max-width: 80%;
  }
  .home-featured-card--large .home-featured-card__title-read-more, .home-featured-card--large-with-image-padding .home-featured-card__title-read-more {
    display: block;
    font-size: 16px;
    text-decoration: underline;
    color: #262F2C !important;
  }
}
.home-featured-card--large-with-image-padding .home-featured-card__image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-featured-card--large-with-image-padding .home-featured-card__image img {
  object-fit: initial;
  width: 60%;
  height: auto;
}

.home-featured-links {
  padding: 20px 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 33px;
}
.home-featured-links__block-title {
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.home-featured-links__block-title:hover {
  text-decoration: underline;
}
.home-featured-links__block-list {
  font-size: 14px;
}
.home-featured-links__block-list-item-link:hover {
  text-decoration: underline;
}
@media screen and (min-width: 768px), print {
  .home-featured-links {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .home-featured-links {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (min-width: 1217px) {
  .home-featured-links {
    padding: 30px 25px;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("../../../fonts/icomoon.eot?xq9ik8");
  src: url("../../../fonts/icomoon.eot?xq9ik8#iefix") format("embedded-opentype"), url("../../../fonts/icomoon.ttf?xq9ik8") format("truetype"), url("../../../fonts/icomoon.woff?xq9ik8") format("woff"), url("../../../fonts/icomoon.svg?xq9ik8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class*=icon--] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--anticlockwise-arrow:before {
  content: "\e958";
}

.icon--arrow-left:before {
  content: "\e908";
}

.icon--truck:before {
  content: "\e932";
}

.icon--card-payment:before {
  content: "\e934";
}

.icon--package-delivery:before {
  content: "\e935";
}

.icon--case:before {
  content: "\e936";
}

.icon--filters-filled:before {
  content: "\e933";
}

.icon--mail:before {
  content: "\e900";
}

.icon--reconditioned:before {
  content: "\e92f";
}

.icon--check-macaroon:before {
  content: "\e930";
}

.icon--medal:before {
  content: "\e931";
}

.icon--simple-minus:before {
  content: "\e92d";
}

.icon--simple-plus:before {
  content: "\e92e";
}

.icon--star-angular:before {
  content: "\e921";
}

.icon--picto-check:before {
  content: "\e92a";
}

.icon--camera:before {
  content: "\e924";
}

.icon--cb:before {
  content: "\e923";
}

.icon--edit:before {
  content: "\e920";
}

.icon--comment:before {
  content: "\e901";
}

.icon--like:before {
  content: "\e902";
}

.icon--trash:before {
  content: "\e91c";
}

.icon--sort:before {
  content: "\e91b";
}

.icon--star-filled:before {
  content: "\e91a";
}

.icon--star:before {
  content: "\e903";
}

.icon--angle-down {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon--angle-down:before {
  content: "\e904";
}

.icon--angle-right {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(270deg);
}
.icon--angle-right:before {
  content: "\e904";
}

.icon--angle-left {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
}
.icon--angle-left:before {
  content: "\e904";
}

.icon--angle-up {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
}
.icon--angle-up:before {
  content: "\e904";
}

.icon--filled-arrow-right .path1:before {
  content: "\e90b";
  color: rgb(38, 47, 44);
}

.icon--filled-arrow-right .path2:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon--arrow-right:before {
  content: "\e905";
}

.icon--bell:before {
  content: "\e906";
}

.icon--bell-filed:before {
  content: "\e925";
}

.icon--burger-menu:before {
  content: "\e907";
}

.icon--cross:before {
  content: "\e909";
}

.icon--eye:before {
  content: "\e90a";
}

.icon--filters:before {
  content: "\e90d";
}

.icon--heart:before {
  content: "\e90e";
}

.icon--heart-filed:before {
  content: "\e926";
}

.icon--home:before {
  content: "\e90f";
}

.icon--home-filed:before {
  content: "\e927";
}

.icon--logout:before {
  content: "\e910";
}

.icon--plane:before {
  content: "\e911";
}

.icon--plus:before {
  content: "\e912";
}

.icon--rounded-cross:before {
  content: "\e928";
}

.icon--question:before {
  content: "\e913";
}

.icon--search-menu:before {
  content: "\e914";
}

.icon--search:before {
  content: "\e915";
}

.icon--shield:before {
  content: "\e916";
}

.icon--shield-filled:before {
  content: "\e90c";
}

.icon--signal:before {
  content: "\e917";
}

.icon--trust:before {
  content: "\e918";
}

.icon--user:before {
  content: "\e919";
}

.icon--warning:before {
  content: "\e91d";
}

.icon--wifi:before {
  content: "\e91e";
}

.icon--search-menu-light:before {
  content: "\e91f";
}

.icon--angle-left-bold:before {
  content: "\e922";
}

.icon--share:before {
  content: "\e929";
}

.icon--check:before {
  content: "\e92b";
}

.icon--trend-up:before {
  content: "\e93c";
}

.icon--cart-market:before {
  content: "\e93b";
}

.icon--arrows-circle:before {
  content: "\e93a";
}

.icon--notice-pen:before {
  content: "\e939";
}

.icon--coin-stack:before {
  content: "\e938";
}

.icon--double-chevron-right:before {
  content: "\e937";
}

.icon--lock:before {
  content: "\e940";
}

.icon--stopwatch:before {
  content: "\e93d";
}

.icon--magic:before {
  content: "\e93e";
}

.icon--headset:before {
  content: "\e93f";
}

.icon--lock:before {
  content: "\e940";
}

.icon--back:before {
  content: "\e947";
}

.icon--truck-outline:before {
  content: "\e943";
}

.icon--fire:before {
  content: "\e942";
}

.icon--edit-filled:before {
  content: "\e944";
}

.icon--shield-outline:before {
  content: "\e945";
}

.icon--chat:before {
  content: "\e946";
}

.icon--chat-question:before {
  content: "\e948";
}

.icon--cb-outline-thin:before {
  content: "\e941";
}

.icon--message:before {
  content: "\e949";
}

.icon--phone:before {
  content: "\e94a";
}

.icon--notification:before {
  content: "\e94b";
}

.icon--check-circle-filled:before {
  content: "\e94c";
}

.icon--eye-crossed:before {
  content: "\e94d";
}

.icon--gavel:before {
  content: "\e94e";
}

.icon--pencil:before {
  content: "\e94f";
}

.icon--download:before {
  content: "\e950";
}

.icon--move:before {
  content: "\e951";
}

.icon--check-thin:before {
  content: "\e952";
}

.icon--plus-thin:before {
  content: "\e953";
}

.icon--calendar:before {
  content: "\e954";
}

.icon--info:before {
  content: "\e955";
}

.icon--paste:before {
  content: "\e956";
}

.icon--circle-exclamation:before {
  content: "\e957";
}

.icon--circle-info {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
}
.icon--circle-info:before {
  content: "\e957";
}

.icon--skis:before {
  content: "\e959";
}

.icon--level:before {
  content: "\e95a";
}

.icon--flash:before {
  content: "\e95c";
}

.icon--battery:before {
  content: "\e95d";
}

.icon--magic-outline:before {
  content: "\e967";
}

.icon--mesure:before {
  content: "\e95f";
}

.icon--bicycle-frame:before {
  content: "\e95b";
}

.icon--bicycle-fork:before {
  content: "\e960";
}

.icon--mountains-outline:before {
  content: "\e961";
}

.icon--bicycle-group:before {
  content: "\e962";
}

.icon--shield-outline-sharp:before {
  content: "\e963";
}

.icon--open-box-outline:before {
  content: "\e964";
}

.icon--clock:before {
  content: "\e965";
}

.icon--store:before {
  content: "\e966";
}

.hero-swiper {
  position: relative;
  z-index: 0;
}
.hero-swiper .swiper {
  min-height: 214px;
}
.hero-swiper .swiper .swiper-slide {
  height: auto;
}
.hero-swiper__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media screen and (min-width: 1024px) {
  .hero-swiper {
    padding: 30px 25px 0 25px;
  }
  .hero-swiper .swiper {
    min-height: 233px;
  }
  .hero-swiper__image img {
    border-radius: 4px;
  }
}
.hero-swiper__pagination {
  position: absolute;
  z-index: 10;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
}
.hero-swiper__navigation {
  display: none;
}
@media screen and (min-width: 1024px) {
  .hero-swiper__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    overflow: hidden;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 10;
    cursor: pointer;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  }
  .hero-swiper__navigation:before {
    content: "\e904";
    font-family: "icomoon";
    font-size: 17px;
    color: #262F2C;
  }
  .hero-swiper__navigation:hover:before {
    color: #BE4A09;
  }
  .hero-swiper__navigation--next {
    right: 50px;
  }
  .hero-swiper__navigation--next:before {
    transform: rotate(-90deg);
  }
  .hero-swiper__navigation--prev {
    left: 50px;
  }
  .hero-swiper__navigation--prev:before {
    transform: rotate(90deg);
  }
}