@import "../../bulma-custom/utilities/mixins.sass"

.concept
  .swiper
    +mobile
      overflow: visible

  .swiper-container
    width: 100%
    height: 100%

  .swiper-slide
    width: 50%
    +mobile
      width: calc(100% - 24px)
    > *
      margin-left: 12px
      margin-right: 12px
