.home-featured-card
  box-sizing: border-box
  width: 125px
  height: 125px
  border: 1px solid $grey-50
  border-radius: 4px

  user-select: none
  $this: &

  &:hover
    #{$this}__title
      text-decoration: underline

  &__link
    &:hover
      color: $black

  &__image
    height: calc(100% - 31px)
    width: 100%
    img
      object-fit: cover
      width: 100%
      height: 100%
      max-height: 100%
      border-radius: 3px 3px 0 0

  &__content
    padding: 0 10px
    height: 31px
    +flexCenter

  &__title
    text-align: center
    font-size: 14px
    font-weight: $weight-medium
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  &__title-read-more
    display: none

  +tablet
    width: 200px
    height: 179px

    &__image
      height: calc(100% - 39px)

    &__content
      padding: 0 20px
      height: 39px

    &__title
      font-size: 16px


  &--medium
    width: 182px
    height: 209px

    #{$this}
      &__image
        height: calc(100% - 51px)

      &__content
        height: 51px
        border-top: 1px solid $grey-50

    +tablet
      width: 244px
      height: 279px

      #{$this}
        &__image
          height: calc(100% - 68px)

        &__content
          height: 68px


  &--large, &--large-with-image-padding
    width: 229px
    height: 153px

    #{$this}
      &__image
        height: calc(100% - 45px)

      &__content
        height: 45px
        border-top: 1px solid $grey-50

    +tablet
      width: 422px
      height: 225px

      #{$this}
        &__image
          height: calc(100% - 64px)

        &__content
          height: 64px
          display: flex
          justify-content: space-between

        &__title
          max-width: 80%

        &__title-read-more
          display: block
          font-size: 16px
          text-decoration: underline
          color: $black !important

  &--large-with-image-padding
    #{$this}
      &__image
        +flexCenter
        img
          object-fit: initial
          width: 60%
          height: auto
